import { environments } from "./assets/environments";
import { writable } from "svelte/store";

export const channels = {
    checkin: writable()
};

let eventSource;
let loading = false;

const subscribeToAllEvents = eventSource => {
    eventSource.addEventListener("guesthub:checkin:start", (event) => {
        const ev = JSON.parse(event.data);
        channels.checkin.set(Object.assign({}, ev, {
            action: 'started'
        }));
    });
}

export const connectToEventSource = input => {
    eventSource = new window["EventSource"](
        `${environments.eventsApi}/${input.propertyId}/guestapp-events`
    );

    subscribeToAllEvents(eventSource);
    eventSource.onerror = function (event) {
        if (!loading) {
            eventSource.close();
            eventSource = undefined;
            setTimeout(
                () =>
                    connectToEventSource(input),
                5000
            );
            loading = true;
        }
    };
    loading = false;
}