<script>
  import { onMount } from "svelte";
  import { _, locale } from "svelte-i18n";
  import {
    headerInformation,
    goBack,
    backInCurrentPage,
    onClickBackEvent,
    showMenu,
  } from "../header/service";
  import { getReservationInformation, postNewCheckin } from "./service";

  import WelcomeComponent from "./stages/welcome.svelte";
  import DetailSummaryComponent from "./stages/detail-summary.svelte";
  import DataComponent from "./stages/data.svelte";
  import DocumentsComponent from "./stages/documents.svelte";
  import CompanionsComponent from "./stages/companions.svelte";
  import CompanionsDataComponent from "./stages/companions-data.svelte";
  import TermConditionsComponent from "./stages/term-conditions.svelte";
  import SignatureComponent from "./stages/signature.svelte";
  import InvitationComponent from "./stages/invitation.svelte";
  import ReloadingComponent from "../components/reloading-component.svelte";

  import { channels } from "../events.services";

  export let propertyInformation;
  export let guestAppFeatures;
  export let guestAppSettings;
  export let params;
  export let queryParams;

  let loading = true;
  let isADesktop = false;
  const stages = [
    "welcome",
    //"detail-summary", Por ahora no estará disponible esta vista.
    "data",
    "documents",
    "companions",
    "companions-data",
    "term-conditions",
    "signature",
    "invitation",
  ];
  let currentStage = 0;
  let reservation = null;
  let readOnly = $queryParams.var ? $queryParams.var == "iframe" : false;

  const detectIsADesktop = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      isADesktop = false;
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua,
      )
    ) {
      isADesktop = false;
    } else {
      isADesktop = true;
    }
    document
      .querySelector(":root")
      ?.style.setProperty("--font-label", isADesktop ? "0.75rem" : "0.7rem");
  };

  onMount(() => {
    detectIsADesktop();
    window.onresize = () => setTimeout(() => detectIsADesktop(), 100);

    if (!$queryParams.code) {
      channels.checkin.subscribe((request) => {
        if (!reservation || (reservation && !reservation.code)) {
          initComponent(
            request && request.action === "started" ? request.code : "general",
            request && request.action === "started",
          );
        }
      });
    } else {
      initComponent($queryParams.code);
    }
  });

  const initComponent = (codeGuestapp, nextAutomatic = false) => {
    loading = true;
    showMenu.set(false);
    goBack.set(null);
    headerInformation.set({
      title: propertyInformation.name,
      logo: propertyInformation.logo,
    });

    if ($queryParams.var && $queryParams.var == "iframe") {
      switch ($queryParams.view) {
        case "home":
          currentStage = 0;
          break;
        case "form":
          currentStage = 1;
          break;
        case "terms-and-conditions":
          currentStage = 4;
          break;

        default:
          break;
      }
    }

    getReservationInformation({
      propertyInformation: propertyInformation,
      code: codeGuestapp,
      channel: $queryParams.channel || null,
    }).then((reservationInformation) => {
      if (reservationInformation.action == "invitation") {
        currentStage = 7;
        reservation = {
          stage: stages[currentStage],
          urlRedirect: reservationInformation.urlRedirect,
        };
        loading = false;
      } else if (reservationInformation.action == "continue") {
        currentStage = 0;
        reservation = Object.assign({}, reservationInformation.data, {
          stage: stages[currentStage],
        });
        if (nextAutomatic) {
          nextStage();
        }

        loading = false;

        onClickBackEvent.subscribe((backInCurrentPageResponse) => {
          if (backInCurrentPageResponse) {
            backStage();
          }
        });
      } else {
        //Caso error
        console.error(reservationInformation);
      }
    });
  };

  const backStage = () => {
    if (currentStage > 0) {
      /*if (
        reservation.reservationValid ||
        (!reservation.reservationValid && currentStage - 1 != 1)
      ) {*/
      if (currentStage == 5 && reservation.type != null) {
        currentStage -= 2;
      }
      if (currentStage == 5 && reservation.companions.length == 0) {
        --currentStage;
      }
      --currentStage;
      /*} else {
        currentStage -= 2;
      }*/
      if (currentStage == 0) {
        goBack.set(null);
        backInCurrentPage.set(false);
      } else {
        goBack.set(`/${propertyInformation.id}`);
      }
      reservation.stage = stages[currentStage];
    }
  };

  const nextStage = () => {
    if (currentStage + 1 <= stages.length - 2) {
      /*if (
        reservation.reservationValid ||
        (!reservation.reservationValid && currentStage + 1 != 1)
      ) {*/
      if (currentStage == 2 && reservation.type != null) {
        ++currentStage;
      }
      if (currentStage == 3 && reservation.companions.length == 0) {
        ++currentStage;
      }
      ++currentStage;
      /*} else {
        currentStage += 2;
      }*/
      reservation.stage = stages[currentStage];
      if (
        propertyInformation.id === "155" &&
        (stages[currentStage] === "companions" ||
          stages[currentStage] === "companions-data")
      ) {
        currentStage = 5;
        reservation.stage = stages[currentStage];
      }
      if (currentStage > 0) {
        goBack.set(`/${propertyInformation.id}`);
        backInCurrentPage.set(true);
      } else {
        goBack.set(null);
      }
    } else {
      loading = true;
      goBack.set(null);
      ++currentStage;
      reservation.stage = stages[currentStage];
      postNewCheckin({
        propertyId: propertyInformation.id,
        body: reservation,
      })
        .then((response) =>
          response.ok ? response.json() : Promise.reject(response),
        )
        .then((response) => {
          reservation.urlRedirect = response.urlRedirect;
          loading = false;
        })
        .catch((err) => {
          console.error(err);
          loading = false;
          goBack.set(`/${propertyInformation.id}`);
          currentStage = 1;
          reservation.stage = stages[currentStage];
        });
    }
  };

  const rebootProcess = (event) => {
    loading = true;
    currentStage = 0;
    reservation = null;
    reservation = { stage: stages[currentStage] };
    loading = false;
  };
</script>

<!-- {#if isADesktop}
  <QrRedirect /> -->
{#if loading}
  <ReloadingComponent bind:managementColors={guestAppSettings} />
{:else if reservation.stage == "welcome"}
  <WelcomeComponent
    bind:propertyInformation
    bind:guestAppSettings
    bind:readOnly
    bind:queryParams
    on:nextStage={nextStage}
  />
{:else if reservation.stage == "detail-summary"}
  <DetailSummaryComponent
    bind:propertyInformation
    bind:guestAppSettings
    bind:reservation
    bind:readOnly
    on:nextStage={nextStage}
  />
{:else if reservation.stage == "data"}
  <DataComponent
    bind:propertyInformation
    bind:guestAppSettings
    bind:reservation
    bind:isADesktop
    bind:readOnly
    on:nextStage={nextStage}
  />
{:else if reservation.stage == "documents"}
  <DocumentsComponent
    bind:reservation
    bind:propertyInformation
    bind:guestAppSettings
    on:nextStage={nextStage}
  />
{:else if reservation.stage == "companions"}
  <CompanionsComponent
    bind:guestAppSettings
    bind:reservation
    bind:isADesktop
    bind:propertyInformation
    bind:currentStage
    on:nextStage={nextStage}
  />
{:else if reservation.stage == "companions-data"}
  <CompanionsDataComponent
    bind:guestAppSettings
    bind:reservation
    bind:isADesktop
    bind:propertyInformation
    bind:currentStage
    on:nextStage={nextStage}
  />
{:else if reservation.stage == "term-conditions"}
  <TermConditionsComponent
    bind:propertyInformation
    bind:isADesktop
    bind:reservation
    on:nextStage={nextStage}
  />
{:else if reservation.stage == "signature"}
  <SignatureComponent
    bind:reservation
    bind:isADesktop
    bind:propertyInformation
    on:nextStage={nextStage}
  />
{:else if reservation.stage == "invitation"}
  <InvitationComponent
    bind:reservation
    bind:propertyInformation
    bind:queryParams
    on:rebootProcess={rebootProcess}
  />
{/if}
