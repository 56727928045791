<script>
  import { onMount, createEventDispatcher } from "svelte";
  import { _, locale } from "svelte-i18n";

  export let propertyInformation;
  export let reservation;
  export let queryParams;

  const dispatch = createEventDispatcher();
  let disabledButton = false;

  onMount(() => {});

  const onRedirectToGuestapp = () => {
    disabledButton = true;
    if ($queryParams.code) {
      window.location.href = reservation.urlRedirect.concat(
        `&language=${$locale}`,
      );
    } else {
      dispatch("rebootProcess", true);
    }
  };
</script>

<section class="container">
  <div class="my-container-check-in">
    <img src={propertyInformation.logo} alt={propertyInformation.name} />
    <h1>{$_("All our services are at your fingertips")}</h1>
    <p>
      {$_(
        "Thank you for completing your Check-in remotely. Check your benefits in our GuestApp.",
      )}
    </p>
  </div>
</section>

<div class="my-footer-button">
  <div class="row">
    <div class="col-12 offset-md-9 col-md-3">
      <button
        type="button"
        class="btn btn-primary btn-lg btn-block"
        disabled={disabledButton}
        on:click|preventDefault={onRedirectToGuestapp}
      >
        {#if $queryParams.code}
          {$_("Go to GuestApp")}
        {:else}
          {$_("Close")}
        {/if}
      </button>
    </div>
  </div>
</div>

<style>
  .my-container-check-in {
    position: absolute;
    margin: 0px;
    text-align: center;
    top: 50%;
    left: 50%;
    width: 65%;
    transform: translateY(-50%) translateX(-50%);
  }
  .my-container-check-in img {
    border-radius: 5px;
    margin-bottom: 20px;
    max-width: 200px;
  }
  @media (max-width: 720px) {
    .my-container-check-in {
      width: 90%;
    }
  }
  @media (max-height: 420px) {
    .my-container-check-in {
      padding-top: 36%;
      padding-bottom: 12%;
    }
  }
</style>
